
import Footer from '../footer'
import '../home.css'
import SmartSlider from "react-smart-slider";
import healyLogo from "../../asset/Healy-Horizontal-Logo-with-Dot-RGB@2x-1.png"
import healyDevice from "../../asset/med-device/digital-app-device.png"
const DummyCaption = ({ caption }) => (
    <div style={{
        position: 'absolute',
        right: 100,
        top: 250,
        fontSize: 38,
        padding: 55,
        border: 'solid 1px',
    }}>
        {caption}
    </div>
)

const Healy = () => {
    <div style={{
        position: 'absolute',
        right: 100,
        top: 250,
        fontSize: 38,
        padding: 55,
        border: 'solid 1px',
    }}>
        <img src={healyDevice} alt="healy" />
    </div>
}
const FisrtSection = () => {
    const slidesArray = [
        {
            url: "/medDevice/healy-bg.png",

            // (Optional) Set if you want to add any content on your slide
            childrenElem: <div style={{
                position: 'absolute',
                right: 100,
                top: 80,
                fontSize: 38,
                padding: 55,
            }}>
                <img src={healyLogo} alt="healy" />
                <div style={{
                    position: 'absolute',
                    paddingTop: 25,
                    fontSize: 28,
                    fontFamily: 'Barlow',
                    fontStyle: 'semi-condense-bold',
                    color: 'white',
                    textShadow: '0px 4px 3px rgba(0,0,0,0.4)'

                }}> Medical Device for your wellness</div>

            </div >
        },
        { // healy
            url: "/medDevice/gradient-bg1.jpeg",
            childrenElem: <Healy />
        },
        {
            url: "/medDevice/gradient-bg2.jpeg",
            childrenElem: <DummyCaption caption="Caption 3" />
        },
        {
            url: "/medDevice/gradient-bg3.jpeg",
            childrenElem: <DummyCaption caption="Caption 3" />
        },
        {
            url: "/medDevice/gradient-bg4.jpeg",
            childrenElem: <DummyCaption caption="Caption 3" />
        },
        {
            url: "/medDevice/gradient-bg5.png",
            childrenElem: <DummyCaption caption="Caption 3" />
        },
    ];
    return (<div className='slider-container'>
        <SmartSlider
            slides={slidesArray}
            buttonShape="square" // round or square
            autoPlay="false"
        />
    </div>)
}

// const SecondSection = () => {
//     return (<div className='about-us-white-sec'>
//         <div className='about-us-second-sec-container'>
//             <div className='about-us-box-content'>
//                 <h1>OUR VISION</h1>
//                 <p>Vertical Integration and State of the Art Internal Systems/Solutions<br />
//                     Strong Customer Relations Built on Trust<br />
//                     Solid Financial Position<br />
//                     Employee and Community Respect<br />
//                     Technology<br />
//                     Operational Excellence<br /></p>
//             </div>
//             <div className='about-us-box-content2'>
//                 <h1>OUR MISSION</h1>
//                 <p> &nbsp;&nbsp;Precision Medical Products and Therapy, Inc. is a medical device manufacturer committed to providing the highest level of excellence to our stakeholders through innovation,
//                     vertical integration and comprehensive value-added services to offer a complete single source solution.
//                     <br /> &nbsp;&nbsp;To inspire hope, and contribute to health and well-being by providing the best care to every patient through integrated clinical practice, education and research.</p>
//             </div>

//         </div>
//     </div >)

// }

// const ThirdSection = () => {
//     return (<div className="ceo-section">
//         <div>
//             <img src={ceoPic} alt="ceoPic" />
//             <h1>CEO</h1>
//             <p>Tientam Junjuajarn</p>
//         </div>
//         <div>
//             <h1>COO</h1>
//             <p>XXX XXXXX</p>
//         </div>
//         <div>
//             <h1>Manager</h1>
//             <p>XXX XXXXX</p>
//         </div>
//     </div>)
// }
const MedDevice = props => {
    return (
        <div className='home-page'>
            <div className="home-container">
                <FisrtSection />
                {/* <div className="space-between"></div>
                <SecondSection /> */}
                <Footer />
            </div>
        </div >
    )

}

export default MedDevice